import { Box, Button, Container, FormControl, Grid, IconButton, MenuItem, Paper, Select, Typography } from "@mui/material";
import { CiExport, CiImport } from "react-icons/ci";
import { LiaDownloadSolid, LiaUploadSolid } from "react-icons/lia";
import { IoTimeOutline } from "react-icons/io5";
import { TfiArchive, TfiPrinter, TfiWallet } from "react-icons/tfi";
import CircleModal from "../CircleModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InvestedTotalChart from "./InvestedTotalChart";
import LineWithdrawnChart from "./LineWithdrawnChart";
import LineLast12Chart from "./LineLast12Chart";
import ProjectedChart from "./ProjectedChart";
import uuid from "react-uuid";

const importIconStyle = {
    marginRight: "10px",
}

const priceStyle = {
    fontSize: "21px"
}

const plnStyle = {
    fontSize: "16px"
}

const descStyle = {
    fontSize: "14px",
    color: "#a1aab2"
}

const circleImpExpStyle = {
    width: "50px",
    height: "50px",
    cursor: "auto",
    "&:hover": { backgroundColor: "#4abdac" }
}

const circleDownUploadStyle = {
    width: "50px",
    height: "50px",
    background: "#7460ee",
    cursor: "auto",
    "&:hover": { backgroundColor: "#7460ee" }
}

const circleClockStyle = {
    width: "50px",
    height: "50px",
    background: "#fc4b6c",
    cursor: "auto",
    "&:hover": { backgroundColor: "#fc4b6c" }
}

const circleArchiveStyle = {
    width: "50px",
    height: "50px",
    background: "#00519b",
    cursor: "auto",
    "&:hover": { backgroundColor: "#00519b" }
}

const circleWalletStyle = {
    width: "50px",
    height: "50px",
    background: "#00519b",
    cursor: "auto",
    "&:hover": { backgroundColor: "#00519b" }
}

const labelsMonth = [{ id: 1, month: 'Styczeń' }, { id: 2, month: 'Luty' }, { id: 3, month: 'Marzec' }, { id: 4, month: 'Kwiecień' }, { id: 5, month: 'Maj' }, { id: 6, month: 'Czerwiec' }, { id: 7, month: 'Lipiec' }, { id: 8, month: 'Sierpień' }, { id: 9, month: 'Wrzesień' }, { id: 10, month: 'Październik' }, { id: 11, month: 'Listopad' }, { id: 12, month: 'Grudzień' }]

const formatPrice = (priceIn) => {
    let złotówki = Math.floor(priceIn / 100);
    let grosze = priceIn % 100;

    let złotówkiZSpacjami = złotówki.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return złotówkiZSpacjami + (grosze !== 0 ? '.' + (grosze < 10 ? '0' : '') + grosze : '.00');
}

const Statistics = () => {

    const navigate = useNavigate()

    const [username, setUsername] = useState("")
    const [withdrawnMonthly, setWithdrawnMonthly] = useState([])
    const [totalInvest, setTotalInvest] = useState(0.00)
    const [plannedTotalProfit, setPlannedTotalProfit] = useState(0.00)
    const [invested12Months, setInvested12Months] = useState(0.00)
    const [profits12Months, setProfits12Months] = useState(0.00)
    const [lastInvestment, setLastInvestment] = useState(0.00)
    const [averageInterestRate, setAverageInterestRate] = useState(0.00)
    const [averageInterestRate12Months, setAverageInterestRate12Months] = useState(0.00)
    const [personName, setPersonName] = useState([]);
    const [totalProfit, setTotalProfit] = useState(0.00)
    const [countInvestments, setCountsInvestments] = useState(0)
    const [actualCountsInvests, setActualCountsInvests] = useState(0)
    const [projectsProfitMonthly, setProjectsProfitMonthly] = useState([])
    const [projectsProfitMonthlyByName, setprojectsProfitMonthlyByName] = useState([])
    const [isByName, setIsByName] = useState(false)
    const [projectedProfits, setProjectedProfits] = useState(0.00)
    const [totalFundsInvested, setTotalFundsInvested] = useState(0)
    const [statsLoader, setStatsLoader] = useState(true)
    const [labelsWithdrawn, setLabelsWithdrawn] = useState([])
    const [projects, setProjects] = useState([])
    const [labelsProfitsLast12, setLabelsProfitsLast12] = useState([])
    const [currentMonthProfit, setCurrentMonthProfit] = useState()

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );

        if (value === '') {
            getProjectsInfo()
        } else {
            getProjectInfoByName(value)
        }
    };

    const generateLast12Months = () => {
        const currentDate = new Date();
        const months = [];
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        for (let i = 0; i < 12; i++) {
            const month = currentDate.getMonth() - i;
            const year = currentDate.getFullYear();
            months.push({
                month: monthNames[month === -1 ? 11 : month],
                year: month === 0 ? year - 1 : year,
            });
        }
        return setLabelsProfitsLast12(months.reverse());
    };

    const getProjectInfoByName = (name) => {
        setStatsLoader(true)
        const projectsFiltered = projectsProfitMonthly.filter(project => project.name === name)
        setprojectsProfitMonthlyByName(projectsFiltered)
        setTotalInvest(projectsFiltered[0].total_funds_invested)
        setPlannedTotalProfit(projectsFiltered[0].planned_total_investment_profit)
        setInvested12Months(projectsFiltered[0].funds_invested_in_last_12_months)
        setProfits12Months(projectsFiltered[0].profits_over_last_12_months)
        setTotalProfit(projectsFiltered[0].total_profits)
        setProjectedProfits(projectsFiltered[0].projected_profits)
        setWithdrawnMonthly(projectsFiltered[0].funds_withdrawn_quarterly)
        setIsByName(true)
        setTimeout(() => {
            setStatsLoader(false)
        }, 200);
    }

    const getProjectsInfo = (projectName) => {
        setStatsLoader(true)
        setIsByName(false)

        axios("/investments/stats/global/", {
            'Content-Type': 'application/json',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTotalInvest(res.data.total_funds_invested)
                setPlannedTotalProfit(res.data.planned_total_investment_profit)
                setInvested12Months(res.data.funds_invested_in_last_12_months)
                setProfits12Months(res.data.profits_over_last_12_months)
                setLastInvestment(res.data.invested_funds_in_last_investment)
                setAverageInterestRate(res.data.average_interest_rate)
                setAverageInterestRate12Months(res.data.average_interest_rate_over_last_12_months)
                setTotalProfit(res.data.total_profits)
                setCountsInvestments(res.data.investments_no)
                setProjectedProfits(res.data.projected_profits)
                setActualCountsInvests(res.data.investments_in_progress_no)
                setTotalFundsInvested(res.data.total_funds_invested)
                setProjectsProfitMonthly(res.data.projects)
                setStatsLoader(false)
                setWithdrawnMonthly(res.data.funds_withdrawn_quarterly)
                let arrMonths = []
                for (let i = 0; i < res.data.funds_withdrawn_quarterly.length; i++) {
                    arrMonths.push(labelsMonth[res.data.funds_withdrawn_quarterly[i].month - 1].month)
                }
                generateLast12Months()
                setLabelsWithdrawn(arrMonths)
                setCurrentMonthProfit(res.data.current_month_profits)
                let nameArr = []
                res.data.projects.map(project => {
                    return nameArr.push(project.name)
                })
                setProjects(nameArr)
            })
            .catch(err => {
                // navigate("/investments")
            })
    }

    useEffect(() => {
        getProjectsInfo()
        axios("/users/profile/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setUsername(res.data.name)
            })
            .catch(err => navigate("/login"))
    }, [])

    return (
        <Container maxWidth={'lg'} sx={{ marginTop: "100px" }}>
            <Typography
                textAlign={'center'}
                color={'#455a64'}
                fontSize={24}
                marginTop={5}
                marginBottom={6}
            >
                Witaj {username}
            </Typography>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box minWidth={'120px'}>
                    <FormControl fullWidth size="small" style={{ backgroundColor: "#fff" }}>
                        <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={personName}
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>Wszystkie</em>
                            </MenuItem>
                            {
                                projects.map(project =>
                                    <MenuItem value={project}>{project}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
                <IconButton>
                    <Box textAlign={'center'}>
                        <Typography marginBottom={1}>
                            Zapisz PDF
                        </Typography>
                        <TfiPrinter size={"28px"} style={{ marginBottom: "24px" }} />
                    </Box>
                </IconButton>
            </Box>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={6} lg={3} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<CiImport size={30} />} circleStyle={circleImpExpStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{formatPrice(totalInvest)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    Zainwestowane środki ogółem
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<CiExport size={30} />} circleStyle={circleImpExpStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{formatPrice(plannedTotalProfit)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    Planowany zysk z inwestycji ogółem
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<LiaDownloadSolid size={30} />} circleStyle={circleDownUploadStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{formatPrice(invested12Months)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    Zainwestowane środki w ostatnich 12 miesiącach
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<LiaUploadSolid size={30} />} circleStyle={circleDownUploadStyle} width={"50px"} height={"50px"} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{formatPrice(profits12Months)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    Zyski z ostatnich 12 miesięcy
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={4} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<IoTimeOutline size={30} />} circleStyle={circleClockStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{formatPrice(lastInvestment)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    Zainwestowane środki w ostatnią inwestycję
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<TfiArchive size={30} />} circleStyle={circleArchiveStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{averageInterestRate} </span>
                                    %
                                </Typography>
                                <Typography sx={descStyle}>
                                    Średnie oprocentowanie na wszystkie inwestycje
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'}>
                            <CircleModal icon={<TfiWallet size={30} />} circleStyle={circleWalletStyle} iconpopupstyle={importIconStyle} />
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    <span style={priceStyle}>{averageInterestRate12Months} </span>
                                    %
                                </Typography>
                                <Typography sx={descStyle}>
                                    Średnie oprocentowanie na wszystkie inwestycje w ostatnich 12 miesiącach
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={6} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box>
                                <Typography fontSize={plnStyle}>
                                    Całkowite zyski
                                </Typography>
                                <Typography fontSize={32} color={'#0bb6ad'}>
                                    <span style={{ fontSize: "40px" }}>{formatPrice(totalProfit)} </span>
                                    PLN
                                </Typography>
                                <Typography sx={descStyle}>
                                    <span>{formatPrice(projectedProfits)} </span> PLN do końca inwestycji
                                </Typography>
                                <Typography fontSize={16} color={"#455a64"}>
                                    {actualCountsInvests} inwestycji w toku
                                </Typography>
                            </Box>
                            <Box position={'relative'}>
                                <ProjectedChart totalInvestment={projectedProfits + totalProfit} loadingChart={statsLoader} projects={[totalProfit, projectedProfits,]} circularSize={20} chartjsSize={{ height: "180px", width: "180px" }} />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'center'} backgroundColor={"#0bb6ad"}>
                            <Box width={'100%'}>
                                <Typography fontSize={plnStyle}>
                                    Moje inwestycje
                                </Typography>
                                <Typography fontSize={36} color={'#455a64'} textAlign={'center'}>
                                    {countInvestments} inwestycji
                                </Typography>
                                <Typography fontSize={21} color={'#455a64'} textAlign={'center'} marginBottom={2}>
                                    {actualCountsInvests} trwające
                                </Typography>
                                <Button variant='contained' fullWidth>
                                    Pokaż wszystkie
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={4} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'flex-start'} flexDirection={'column'} justifyContent={'flex-start'}>
                            <Typography fontSize={18} marginBottom={2}>Zainwestowane środki</Typography>
                            <Box position={'relative'}>
                                <InvestedTotalChart
                                    totalInvestment={totalFundsInvested}
                                    loadingChart={statsLoader}
                                    projects={isByName ? projectsProfitMonthlyByName : projectsProfitMonthly}
                                    circularSize={300}
                                    chartjsSize={{ height: "300px", width: "300px" }}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
                            <Typography fontSize={18} marginBottom={2}>Wypłacone Zyski </Typography>
                            <Box position={'relative'} width={"100%"} height={"300px"}>
                                <LineWithdrawnChart labelsWithdrawn={labelsWithdrawn} withdrawnMonthly={withdrawnMonthly} statsLoader={statsLoader} />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12}  >
                    <Paper>
                        <Box p={3} display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
                            <Typography fontSize={18} >Miesięczne zyski </Typography>
                            <Typography fontSize={18} marginBottom={2}>Ostatnie 12 miesięcy </Typography>
                            <LineLast12Chart projectsProfitMonthly={isByName ? projectsProfitMonthlyByName : projectsProfitMonthly} statsLoader={statsLoader} />
                            <Grid container marginTop={2}>
                                <Grid item xs={12} sm={4}>
                                    <Typography fontSize={36}>{formatPrice(totalProfit)} PLN</Typography>
                                    <Typography fontSize={14}>Zysk całkowity</Typography>
                                </Grid>
                                {
                                    isByName
                                        ?
                                        projectsProfitMonthlyByName && projectsProfitMonthlyByName.map(project => {
                                            return (
                                                <Grid item xs={12} sm={4} key={uuid()}>
                                                    <Typography fontSize={36}>
                                                        {formatPrice(project.profits_monthly[projectsProfitMonthly[0].profits_monthly.length - 1].amount)} PLN
                                                    </Typography>
                                                    <Typography fontSize={14}>{project.name}</Typography>
                                                </Grid>
                                            )
                                        })
                                        :
                                        projectsProfitMonthly && projectsProfitMonthly.map(project => {
                                            return (
                                                <Grid item xs={12} sm={4} key={uuid()}>
                                                    <Typography fontSize={36}>
                                                        {formatPrice(project.profits_monthly[projectsProfitMonthly[0].profits_monthly.length - 1].amount)} PLN
                                                    </Typography>
                                                    <Typography fontSize={14}>{project.name}</Typography>
                                                </Grid>
                                            )
                                        })}
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Statistics