import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoAlertCircleSharp } from "react-icons/io5";
import { RiCheckboxCircleFill } from "react-icons/ri";
import VerifyModal from "../VerifyModal";
import { app } from "../../../firebase.config";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";


const Verification = () => {

    const [isEmail, setIsEmail] = useState(false)
    const [isPhone, setIsPhone] = useState(false)
    const [openEmail, setOpenEmail] = useState(false)
    const [openPhone, setOpenPhone] = useState(false)
    const [codeValueEmail, setCodeValueEmail] = useState("")
    const [emailAccess, setEmailAccess] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [codeValuePhone, setCodeValuePhone] = useState("")
    const [confirmationResult, setConfirmationResult] = useState()

    const handleOpenEmail = () => setOpenEmail(true)
    const handleCloseEmail = () => setOpenEmail(false)
    const handleOpenPhone = () => setOpenPhone(true)
    const handleClosePhone = () => setOpenPhone(false)

    const handleChangeEmail = (e) => {
        setCodeValueEmail(e.target.value)
    }

    const handleChangePhone = (e) => {
        setCodeValuePhone(e.target.value)
    }

    const handleSubmitEmail = async () => {
        await axios.post("/users/verify/email-confirm/", {
            "access": emailAccess,
            "code": codeValueEmail
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {

                handleCloseEmail()
                getProfileData()
            })
            .catch(err => {
                console.log("res: ", err)
            })
    }



    const handleIsEmail = async () => {
        await axios.post("/users/verify/email-resend/", {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setEmailAccess(res.data.access)
                handleOpenEmail()
            })
            .catch(err => {
                console.log("res: ", err)
            })
    }
    const handleIsPhone = () => {
        let phoneVerifiedNum = phoneNumber
        const auth = getAuth(app);
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {});

        const appVerifier = window.recaptchaVerifier;

        if (!phoneNumber.includes('+')) {
            phoneVerifiedNum = "+48" + phoneNumber
        }
        signInWithPhoneNumber(auth, phoneVerifiedNum, appVerifier)
            .then((confirmationResult) => {
                setConfirmationResult(confirmationResult)
                handleOpenPhone()
            }).catch((error) => {
                console.error(error)
                // Error; SMS not sent
                // ...
            });

    }

    const handleSubmitPhone = () => {

        confirmationResult.confirm(codeValuePhone)
            .then((result) => {
                // User signed in successfully.
                const user = result.user;
                // ...
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
    }

    const getProfileData = async () => {
        await axios("/users/profile/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setIsPhone(res.data.phone_verified)
                setIsEmail(res.data.email_verified)
                setPhoneNumber(res.data.phone_number)
            })
            .catch(err => {

            })
    }

    useEffect(() => {
        getProfileData()
    }, [])

    return (
        <Box>
            <VerifyModal
                open={openEmail}
                handleOpen={handleOpenEmail}
                handleClose={handleCloseEmail}
                setChange={handleChangeEmail}
                handleSubmit={handleSubmitEmail}
            />
            <VerifyModal
                open={openPhone}
                handleOpen={handleOpenPhone}
                handleClose={handleClosePhone}
                setChange={handleChangePhone}
                handleSubmit={handleSubmitPhone}
            />
            <Typography fontSize={32} textAlign={'center'} color={'#67757c'}>Proces weryfikacji konta</Typography>
            <Grid container>
                <Grid item xs={5} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                    <Typography fontSize={24}>
                        Adres e-mail
                    </Typography>
                </Grid>
                <Grid item xs={7} display={'flex'} alignItems={'center'}>
                    {
                        isEmail
                            ?
                            <RiCheckboxCircleFill size={79} color="#0bb6ad" />
                            :
                            <>
                                <IoAlertCircleSharp size={79} color="#ffb22b" />
                                <Button
                                    variant="contained"
                                    onClick={handleIsEmail}
                                    sx={{
                                        background: "#ffb22b",
                                        color: "#212529",
                                        fontWeight: "700",
                                        '&:hover': {
                                            backgroundColor: '#ffa405',
                                            boxShadow: "0 8px 15px rgb(255 178 43 / 30%)"
                                        },
                                    }}
                                >
                                    Wyślij ponownie
                                </Button>
                            </>
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={5} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                    <Typography fontSize={24}>
                        Numer telefonu
                    </Typography>
                </Grid>
                <Grid item xs={7} display={'flex'} alignItems={'center'}>
                    {
                        isPhone
                            ?
                            <RiCheckboxCircleFill size={79} color="#0bb6ad" />
                            :
                            <>
                                <IoAlertCircleSharp size={79} color="#ffb22b" />
                                <Button
                                    variant="contained"
                                    onClick={handleIsPhone}
                                    sx={{
                                        background: "#ffb22b",
                                        color: "#212529",
                                        fontWeight: "700",
                                        '&:hover': {
                                            backgroundColor: '#ffa405',
                                            boxShadow: "0 8px 15px rgb(255 178 43 / 30%)"
                                        },
                                    }}
                                >
                                    Zweryfikuj
                                </Button>
                            </>
                    }

                </Grid>

            </Grid>
            <Box display={'flex'} justifyContent={'center'}>
                <div id="recaptcha"></div>
            </Box>
        </Box>
    )
}

export default Verification