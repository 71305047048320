import axios from 'axios'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const Notification = () => {

    const { id } = useParams()

    useEffect(() => {
        axios.patch(`/notify/notifications/${id}/`, {
            "seen": true
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
            })
            .catch(err => { console.error(err) })
    }, [])

    return (
        <div style={{ marginTop: "100px" }}>notification1</div>
    )
}

export default Notification