import { Container, Alert, Box, TextField, Typography, Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const RegisterEmailVerify = () => {

    const navigate = useNavigate()
    const [message, setMessage] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const access = searchParams.get('access')

    const handleVerify = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const code = data.get("code");
        axios.post("/users/verify/email-confirm/", {
            "access": access,
            "code": code
        })
            .then(res => {
                setTimeout(() => {
                    navigate("/login")
                }, 2000)
            })
            .catch(err => {
                setMessage(err.response.data)
            })
    }

    return (
        <Container >
            <Box
                component="form"
                noValidate
                onSubmit={handleVerify}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    px: "23%",
                }}
            >
                <Typography component="h1" variant="h3" sx={{ marginBottom: "10px", textAlign: "center" }}>Wprowadź kod weryfikacyjny</Typography>
                <Typography component="p" fontSize={14} sx={{ marginBottom: "10px", textAlign: "center" }}>Ze względów bezpieczeństwa na Twój adres email zostanie wysłany 6-cyfrowy kod autoryzacyjny ważny przez 30 min. Aby się zalogować podaj kod</Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Kod"
                    placeholder="1234"
                    name="code"
                    type="text"
                    autoComplete="none"
                    autoFocus
                    variant="filled"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Potwierdź
                </Button>
                {
                    message &&
                    <Alert sx={{ mb: 4 }} severity="error"> {message}</Alert>
                }
            </Box>
        </Container>
    )
}

export default RegisterEmailVerify;